<template>
  <div>
    <v-card>
      <v-card-title>Laporan Accounting</v-card-title>

      <v-divider></v-divider>

      <v-list>
        <v-list-item-group color="primary">
          <template v-for="(report, index) in reports">
            <v-list-item :key="index" :to="report.link">
              <v-list-item-icon>
                <v-badge v-if="report.notification === true" color="red">
                  <v-icon v-text="report.icon"></v-icon>
                </v-badge>

                <v-icon v-else v-text="report.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="report.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Sales',

  data: () => {
    return {
      reports: [
        {
          icon: 'mdi-notebook',
          title: 'Jurnal Kas/Bank',
          link: '/accounting/banks/journals',
        },
        {
          icon: 'mdi-login-variant',
          title: 'Pemasukan',
          link: '/accounting/deposits',
        },
        {
          icon: 'mdi-logout-variant',
          title: 'Pengeluaran',
          link: '/accounting/expenses',
        },
        {
          icon: 'mdi-transfer',
          title: 'Transfer Bank',
          link: '/accounting/bank-transfers',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.filter {
  margin-bottom: 20px;
}
</style>
